import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { Divider } from 'wix-ui-tpa/Divider';
import { classes, st } from './BookingDetails.st.css';
import { BookingDetailsDataHooks } from './dataHooks';
import { BookingDetailsViewModel } from '../../ViewModel/BookingDetailsViewModel/bookingDetailsViewModel';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { ReactComponent as CameraSVG } from 'wix-ui-tpa/dist/src/assets/icons/Camera.svg';
import { Button, SIZE } from 'wix-ui-tpa/Button';
import { useFormActions } from '../../Hooks/useFormActions';

export interface BookingDetailsProps {
  viewModel: BookingDetailsViewModel;
}
const BookingDetails: React.FC<BookingDetailsProps> = ({
  viewModel,
}: {
  viewModel: BookingDetailsViewModel;
}) => {
  return (
    <>
      <Details viewModel={viewModel} />
      <BookButton viewModel={viewModel} />
    </>
  );
};
const Details: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const {
    sectionTitle,
    videoConferenceBadgeText,
    serviceName,
    dateAndTime,
    duration,
    staffMemberNames,
    location,
  } = viewModel;

  return (
    <div className={st(classes.root, { isMobile })} dir={isRTL ? 'rtl' : 'ltr'}>
      <Text
        data-hook={BookingDetailsDataHooks.HEADER_TITLE}
        className={st(classes.title)}
        tagName={AccessibilityHtmlTags.SecondaryHeading}
      >
        {t(sectionTitle)}
      </Text>
      <Divider
        className={classes.divider}
        data-hook={BookingDetailsDataHooks.DIVIDER}
      />
      {videoConferenceBadgeText && (
        <Badge
          data-hook={BookingDetailsDataHooks.VIDEO_CONFERENCE_BADGE}
          priority={BADGE_PRIORITY.light}
          icon={<CameraSVG />}
          className={classes.videoConferenceBadge}
        >
          {t(videoConferenceBadgeText)}
        </Badge>
      )}

      <Text
        data-hook={BookingDetailsDataHooks.SERVICE_NAME}
        className={classes.primaryText}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {serviceName}
      </Text>
      <Text
        data-hook={BookingDetailsDataHooks.SLOT_DATE_AND_TIME}
        className={classes.primaryText}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {dateAndTime}
      </Text>

      <Text
        data-hook={BookingDetailsDataHooks.DURATION}
        className={classes.secondaryText}
        tagName={AccessibilityHtmlTags.Paragraph}
        aria-label={duration.durationAriaText}
      >
        {duration.durationText}
      </Text>

      <Text
        data-hook={BookingDetailsDataHooks.STAFF_MEMBER}
        className={classes.secondaryText}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {staffMemberNames}
      </Text>

      <Text
        data-hook={BookingDetailsDataHooks.LOCATION}
        className={classes.secondaryText}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {location}
      </Text>
    </div>
  );
};

const BookButton: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const { t } = useTranslation();
  const { onSubmit } = useFormActions();
  const { actionLabel } = viewModel;
  return (
    <Button
      size={SIZE.medium}
      className={classes.bookButton}
      data-hook={BookingDetailsDataHooks.ACTION_BUTTON}
      upgrade
      onClick={() => onSubmit()}
    >
      {t(actionLabel)}
    </Button>
  );
};

export default BookingDetails;
