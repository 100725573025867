import React from 'react';
import { FormActions } from '../Actions/actions';

export const FormActionsContext = React.createContext<FormActions>(
  {} as FormActions,
);

export const FormActionsConsumer = FormActionsContext.Consumer;
export const FormActionsProvider = FormActionsContext.Provider;

export const useFormActions = () => React.useContext(FormActionsContext);
