import React, { FC } from 'react';
import { WidgetProps, useEnvironment } from '@wix/yoshi-flow-editor';
import { withWidgetWCS } from '@wix/yoshi-flow-editor/wcs/wrappers';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import { st, classes } from './Widget.st.css';
import { FormActions } from '../Actions/actions';
import { FormViewModel } from '../ViewModel/viewModel';
import { Form } from '@wix/forms-ui/tpa';
import BookingDetails from './BookingDetails/BookingDetails';
import { FormActionsProvider } from '../Hooks/useFormActions';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

export type ControllerProps = {
  actions: FormActions;
} & FormViewModel;

const Widget: FC<WidgetProps<ControllerProps>> = ({
  bookingDetailsViewModel,
  formSchemaViewModel,
  actions,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <FormActionsProvider value={actions}>
      <div className={st(classes.root, {})} data-hook="BookingsForm-wrapper">
        <div className={classes.backButton}>Back</div>
        <div className={classes.body}>
          <div className={classes.formWrapper}>
            {formSchemaViewModel ? (
              <Form
                translateState={() => ''}
                theme={settings.get(settingsParams.fieldsBorderStyle)}
                className={classes.form}
                schema={formSchemaViewModel}
                onSubmit={() => {}}
                isMobile={isMobile}
              />
            ) : undefined}
          </div>
          <div className={classes.paymentDetails}>
            <BookingDetails viewModel={bookingDetailsViewModel} />
          </div>
        </div>
      </div>
    </FormActionsProvider>
  );
};

export default Widget;
export const BookingsFormWCS = withWidgetWCS(
  Widget,
  settingsParams,
  stylesParams,
);
